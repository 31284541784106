<template>
    <div style="width: 100%; overflow: hidden; position: relative;min-height: 600px" id="parent">
<!--        <div class="left_tab_parent" :style="{ height: tab_height }" style="min-height: 500px">-->
<!--            <div class="left_tab">-->
<!--                <div-->
<!--                        v-for="(item, index) in tab_list"-->
<!--                        :key="index"-->
<!--                        :class="{ isChoose: isChoose == index }"-->
<!--                        @click="showTab(index)"-->
<!--                >-->
<!--                    {{ item }}-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="right_content_parent">
            <div class="right_content">
                <div
                        v-show="nowIndex == 1"
                        style="height: auto;padding-bottom: 20px; border: 1px solid rgb(218, 226, 229)"
                >
                    <div
                            style="
              height: 270px;
              width: 80%;
              margin-left: 50%;
              transform: translateX(-50%);
              display: flex;
              align-items: center;
            "
                    >
                        <Steps :current="current">
                            <Step title="填写基本信息"></Step>
                            <Step title="填写企业知识产权状况"></Step>
                            <Step title="确认企业财务及研发管理状况"></Step>
                            <Step title="提交生成测评报告"></Step>
                        </Steps>
                    </div>
                    <div v-show="current == 0">
                        <div
                                style="
                width: 90%;
                margin-left: 50%;
                transform: translateX(-50%);
                height: 550px;
                border: 1px solid rgba(205, 240, 243, 1);
              "
                        >
                            <Row>
                                <Col span="10">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        企业统一信用代码
                                    </div>
                                </Col>
                                <Col span="14" style="border-top: none">
                                    <div
                                            class="table_div table_div_two"
                                            style="border-top: none !important"
                                    >
                                        <Input
                                                v-model="baseForm.creditCode"
                                                style="height: 50px"
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        企业注册时间
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two" style="border-top: 1px solid #cdf0f3">
                                        <DatePicker
                                                :transfer="true"
                                                style="width: 50%!important;height: 90%!important;"
                                                ref="startTime"
                                                type="date"
                                                v-model="baseForm.registerTime"
                                        ></DatePicker>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        联系人
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                                v-model="baseForm.contactUser"
                                                style="height: 50px"
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        联系方式
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                                v-model="baseForm.contactTel"
                                                style="height: 50px"
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        职工总数
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                                v-model="baseForm.staffTotal"
                                                style="height: 50px"
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        其中：&nbsp;科技人员数
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                                v-model="baseForm.researcherNumber"
                                                style="height: 50px"
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        企业所在省市
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div
                                            class="table_div table_div_two"
                                            style="
                      border-top: 1px solid #cdf0f3 !important;
                      border-bottom: none !important;
                    "
                                    >
                                        <Select v-model="baseForm.provinceCode" :transfer="transfer" style="width: 30%!important;"
                                                @on-change="getProvinceT">
                                            <Option :value="Object.keys(provinceData)[0]">{{ provinceData[Object.keys(provinceData)[0]] }}
                                            </Option>
                                        </Select>&nbsp;&nbsp;省
                                        <Select v-model="baseForm.cityCode" :transfer="transfer" style="width: 30%!important;"
                                                @on-change="getCity">
                                            <Option :key="index" v-for="(value,key,index) in cityData" :value="key">{{ value }}</Option>
                                        </Select>&nbsp;&nbsp;市
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        企业地址
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div
                                            class="table_div table_div_two"
                                            style="
                      border-top: 1px solid #cdf0f3 !important;
                      border-bottom: none !important;
                    "
                                    >
                                        <Input
                                                v-model="baseForm.detailAddress"
                                                style="height: 50px"
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        执政处罚情况
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div
                                            class="table_div table_div_two"
                                            style="
                      border-bottom: 1px solid #cdf0f3 !important;
                      border-top: 1px solid #cdf0f3 !important;
                    "
                                    >
                                        <RadioGroup v-model="baseForm.punishment">
                                            <Radio label="1"><span>有</span></Radio>
                                            <Radio label="0"><span>无</span></Radio>
                                        </RadioGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        主要产品技术领域
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div
                                            class="table_div table_div_two"
                                            style="border-bottom: 1px solid #cdf0f3 !important"
                                    >

                                        <Select v-model="baseForm.mptCode" :transfer="transfer">
                                            <Option
                                                    v-for="(item, index) in technologyList"
                                                    :key="index"
                                                    :value="item.value"
                                            >{{ item.name }}
                                            </Option>
                                        </Select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div
                                            class="table_div"
                                            style="
                      background-color: rgba(205, 240, 243, 0.3);
                      border-bottom: none;
                    "
                                    >
                                        产品获奖情况
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div
                                            class="table_div table_div_two"
                                            style="border-bottom: none"
                                    >
                                        <Select v-model="baseForm.awards" :transfer="transfer">
                                            <Option value="1">重点新产品</Option>
                                            <Option value="2">高新技术产品</Option>
                                            <Option value="3">名牌产品</Option>
                                            <Option value="3">其他</Option>

                                        </Select>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div
                                style="height: 180px; border-top: none; margin-top: 50px"
                                class="next_style common_liucheng;"
                        >
                            <div
                                    style="height: 40px; width: 120px"
                                    class="button_style"
                                    @click="firstNext()"
                            >
                                下一步
                            </div>
                            <!--              <div-->
                            <!--                  style="-->
                            <!--                  height: 40px;-->
                            <!--                  width: 120px;-->
                            <!--                  background-color: #fc7c40;-->
                            <!--                  margin-left: 20px;-->
                            <!--                "-->
                            <!--                  class="button_style"-->
                            <!--                  @click="confirmBaseBu()"-->
                            <!--              >-->
                            <!--                暂存-->
                            <!--              </div>-->
                        </div>
                    </div>

                    <div v-show="current == 1">
                        <div
                                style="
                width: 90%;
                margin-left: 50%;
                transform: translateX(-50%);
                height: 350px;
                border: 1px solid rgba(205, 240, 243, 1);
              "
                        >
                            <Row>
                                <Col span="8">
                                    <div
                                            class="table_div"
                                            style="
                      background-color: #36c2cf;
                      color: white;
                      border-bottom: none;
                    "
                                    >
                                        | 类知识产权
                                    </div>
                                </Col>
                                <Col span="8">
                                    <div
                                            class="table_div"
                                            style="
                      background-color: rgba(205, 240, 243, 0.3);
                      border-bottom: none;
                    "
                                    >
                                        自主研发获取
                                    </div>
                                </Col>
                                <Col span="8">
                                    <div
                                            class="table_div"
                                            style="
                      background-color: rgba(205, 240, 243, 0.3);
                      border-bottom: none;
                    "
                                    >
                                        受让、受赠和并购获取
                                    </div>
                                </Col>
                            </Row>
                            <div v-for="(item, index) in ipList1" :key="index" class="first">
                                <Row>
                                    <Col span="8">
                                        <div
                                                v-if="index == 0"
                                                class="table_div"
                                                style="background-color: rgba(205, 240, 243, 0.3)"
                                        >
                                            <span>发明专利</span>
                                        </div>
                                        <div
                                                v-if="index == 1"
                                                class="table_div"
                                                style="background-color: rgba(205, 240, 243, 0.3)"
                                        >
                                            <span>植物新品种</span>
                                        </div>
                                        <div
                                                v-if="index == 2"
                                                class="table_div"
                                                style="background-color: rgba(205, 240, 243, 0.3)"
                                        >
                                            <span>国家农作物品种</span>
                                        </div>
                                        <div
                                                v-if="index == 3"
                                                class="table_div"
                                                style="background-color: rgba(205, 240, 243, 0.3)"
                                        >
                                            <span>国家新药</span>
                                        </div>
                                        <div
                                                v-if="index == 4"
                                                class="table_div"
                                                style="background-color: rgba(205, 240, 243, 0.3)"
                                        >
                                            <span>国际一级中药保护品种</span>
                                        </div>
                                        <div
                                                v-if="index == 5"
                                                class="table_div"
                                                style="background-color: rgba(205, 240, 243, 0.3)"
                                        >
                                            <span>集成电路布局设计专有权</span>
                                        </div>
                                    </Col>
                                    <Col span="8">
                                        <div class="table_div2">
                                            <Input
                                                    v-model="item.selfDevNumber"
                                                    style="height: 50px"
                                            ></Input>
                                        </div>
                                    </Col>
                                    <Col span="8">
                                        <div class="table_div2">
                                            <Input
                                                    v-model="item.traGiftMergerNumber"
                                                    style="height: 50px"
                                            ></Input>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div
                                style="
                width: 90%;
                margin-left: 50%;
                transform: translateX(-50%);
                height: 150px;
                border: 1px solid rgba(205, 240, 243, 1);
                margin-top: 50px;
                border-bottom: none;
              "
                        >
                            <Row>
                                <Col span="8">
                                    <div
                                            class="table_div"
                                            style="background-color: #36c2cf; color: white"
                                    >
                                        || 类知识产权
                                    </div>
                                </Col>
                                <Col span="8">
                                    <div
                                            class="table_div"
                                            style="
                      background-color: rgba(205, 240, 243, 0.3);
                      border-bottom: none;
                    "
                                    >
                                        自主研发获取
                                    </div>
                                </Col>
                                <Col span="8">
                                    <div
                                            class="table_div"
                                            style="
                      background-color: rgba(205, 240, 243, 0.3);
                      border-bottom: none;
                    "
                                    >
                                        受让、受赠和并购获取
                                    </div>
                                </Col>
                            </Row>
                            <div v-for="(item, index) of ipList2" :key="index">
                                <Row>
                                    <Col span="8">
                                        <div
                                                v-if="index == 0"
                                                class="table_div"
                                                style="background-color: rgba(205, 240, 243, 0.3)"
                                        >
                                            <span>实用新型专利</span>
                                        </div>
                                        <div
                                                v-if="index == 1"
                                                class="table_div"
                                                style="background-color: rgba(205, 240, 243, 0.3)"
                                        >
                                            <span>软件著作权</span>
                                        </div>
                                    </Col>
                                    <Col span="8">
                                        <div class="table_div2">
                                            <Input
                                                    v-model="item.selfDevNumber"
                                                    style="height: 50px"
                                            ></Input>
                                        </div>
                                    </Col>
                                    <Col span="8">
                                        <div class="table_div2">
                                            <Input
                                                    v-model="item.traGiftMergerNumber"
                                                    style="height: 50px"
                                            ></Input>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div
                                style="
                width: 90%;
                margin-left: 50%;
                transform: translateX(-50%);
                height: 150px;
                border: 1px solid rgba(205, 240, 243, 1);
                margin-top: 50px;
                border-bottom: none;
              ">
                            <Row>
                                <Col span="10">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        与主要产品或服务相关的知识产权数量
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div
                                            class="table_div table_div_two"
                                            style="
                      border-top: 1px solid #cdf0f3 !important;
                      border-bottom: none !important;
                    "
                                    >
                                        <Input
                                                v-model="relatedNumber"
                                                style="height: 50px"
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        近三年知识产权总量（含已授权和申请中）
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div
                                            class="table_div table_div_two"
                                            style="
                      border-top: 1px solid #cdf0f3 !important;
                      border-bottom: none !important;
                    "
                                    >
                                        <Input
                                                v-model="threeYearIpTotal"
                                                style="height: 50px"
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        组织参与制定标准、检测方法、技术规范的情况
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div
                                            class="table_div table_div_two"
                                            style="
                      border-bottom: 1px solid #cdf0f3 !important;
                      border-top: 1px solid #cdf0f3 !important;
                    "
                                    >
                                        <RadioGroup v-model="orgParticipation">
                                            <Radio label="1"><span>有</span></Radio>
                                            <Radio label="0"><span>无</span></Radio>
                                        </RadioGroup>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div
                                style="height: 180px; border-top: none; margin-top: 50px"
                                class="next_style common_liucheng;"
                        >
                            <div
                                    style="height: 40px; width: 120px"
                                    class="button_style"
                                    @click="current = 0"
                            >
                                上一步
                            </div>
                            <div
                                    style="height: 40px; width: 120px; margin-left: 20px"
                                    class="button_style"
                                    @click="secondNext()"
                            >
                                下一步
                            </div>
                            <!--              <div-->
                            <!--                  style="-->
                            <!--                  height: 40px;-->
                            <!--                  width: 120px;-->
                            <!--                  background-color: #fc7c40;-->
                            <!--                  margin-left: 20px;-->
                            <!--                "-->
                            <!--                  class="button_style"-->
                            <!--                  @click="confirmIpBu()"-->
                            <!--              >-->
                            <!--                暂存-->
                            <!--              </div>-->
                        </div>

                    </div>
                    <div v-show="current == 2">
                        <div
                                style="
                width: 90%;
                margin-left: 50%;
                transform: translateX(-50%);
                height: 200px;
                border: 1px solid rgba(205, 240, 243, 1);
                border-bottom: none;
              "
                        >
                            <Row>
                                <Col span="6">
                                    <div
                                            class="table_div"
                                            style="
                      background-color: #36c2cf;
                      color: white;
                      border-bottom: none;
                    "
                                    >
                                        <span>年度财务状况</span>
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div
                                            class="table_div"
                                            style="
                      background-color: rgba(205, 240, 243, 0.3);
                      border-bottom: none;
                    "
                                    >
                                        <span>年度销售收入</span>
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div
                                            class="table_div"
                                            style="
                      background-color: rgba(205, 240, 243, 0.3);
                      border-bottom: none;
                    "
                                    >
                                        <span>净资产总额</span>
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div
                                            class="table_div"
                                            style="
                      background-color: rgba(205, 240, 243, 0.3);
                      border-bottom: none;
                    "
                                    >
                                        <span>年度投入总额</span>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col span="6">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        <span>{{ this.thisYear - 3 }}年财务状况</span>
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div class="table_div2">
                                        <Input
                                                v-model="financeFrom.last1stYearSaleRevenue"
                                                style="height: 50px"
                                        />
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div class="table_div2">
                                        <Input
                                                v-model="financeFrom.last1stYearNetAssetsTotal"
                                                style="height: 50px"
                                        />
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div class="table_div2">
                                        <Input
                                                v-model="financeFrom.last1stYearRdInvestmentTotal"
                                                style="height: 50px"
                                        />
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        <span>{{ this.thisYear - 2 }}年财务状况</span>
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div class="table_div2">
                                        <Input
                                                v-model="financeFrom.last2ndYearSaleRevenue"
                                                style="height: 50px"
                                        />
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div class="table_div2">
                                        <Input
                                                v-model="financeFrom.last2ndYearNetAssetsTotal"
                                                style="height: 50px"
                                        />
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div class="table_div2">
                                        <Input
                                                v-model="financeFrom.last2ndYearRdInvestmentTotal"
                                                style="height: 50px"
                                        />
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        <span>{{ this.thisYear - 1 }}年财务状况</span>
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div class="table_div2">
                                        <Input
                                                v-model="financeFrom.last3rdYearSaleRevenue"
                                                style="height: 50px"
                                        />
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div class="table_div2">
                                        <Input
                                                v-model="financeFrom.last3rdYearNetAssetsTotal"
                                                style="height: 50px"
                                        />
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div class="table_div2">
                                        <Input
                                                v-model="financeFrom.last3rdYearRdInvestmentTotal"
                                                style="height: 50px"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div
                                style="
                width: 90%;
                margin-left: 50%;
                transform: translateX(-50%);
                height: 50px;
                border: 1px solid rgba(205, 240, 243, 1);
                margin-top: 50px;
                border-right: none;
                border-bottom: none;
              "
                        >
                            <Row>
                                <Col span="3">
                                    <div
                                            class="table_div yi_hang_sheng"
                                            style="
                      background-color: rgba(205, 240, 243, 0.3);
                      border-bottom: none;
                    "
                                    >
                    <span style="padding-left: 10px" title="前三年度累计研发占比"
                    >前三年度累计研发占比</span
                    >
                                    </div>
                                </Col>
                                <Col span="6">
                                    <div class="table_div">
                                        <Select
                                                v-model="financeFrom.lastYearHighTechRate"
                                                style="width: 96% !important"
                                                :transfer="transfer"
                                        >
                                            <Option value="A"
                                            >累计销售收入≤5000万，研发累计≥5%
                                            </Option
                                            >
                                            <Option value="B"
                                            >5000万＜累计销售收入≤2亿，研发费用累计≥4%
                                            </Option
                                            >
                                            <Option value="C"
                                            >累计销售收入＞2亿，研发费用累计≥3%
                                            </Option
                                            >
                                            <Option value="D">其他</Option>
                                        </Select>
                                    </div>
                                </Col>
                                <Col span="5">
                                    <div
                                            class="table_div yi_hang_sheng"
                                            style="padding-left: 10px;background-color: rgba(205, 240, 243, 0.3);
                      border-bottom: none;
                    "
                                            title="前三年度累计境内研发费用占总研发费用比"
                                    >
                                        <span>前三年度累计境内研发费用占总研发费用比</span>
                                    </div>
                                </Col>
                                <Col span="2">
                                    <div class="table_div">
                                        <Select
                                                v-model="financeFrom.threeYearDomesticRdRate"
                                                style="width: 96% !important"
                                                :transfer="transfer"
                                        >
                                            <Option value="A"> >60% </Option>
                                            <Option value="B"> <60% </Option>
                                        </Select>
                                    </div>
                                </Col>
                                <Col span="5">
                                    <div
                                            class="table_div yi_hang_sheng"
                                            style="
                      background-color: rgba(205, 240, 243, 0.3);
                      border-bottom: none;
                    "
                                    >
                                        <span style="padding-left: 10px">上一年度高新技术产品（服务）收入占比</span>
                                    </div>
                                </Col>
                                <Col span="3">
                                    <div class="table_div">
                                        <Select
                                                v-model="financeFrom.threeYearRdRate"
                                                style="width: 96% !important"
                                                :transfer="transfer"
                                        >
                                            <Option value="A"> >60%</Option>
                                            <Option value="B"> <60%</Option>
                                        </Select>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div
                                style="
                width: 90%;
                margin-left: 50%;
                transform: translateX(-50%);
                height: 200px;
                border: 1px solid rgba(205, 240, 243, 1);
                margin-top: 50px;
                border-bottom: none;
                border-right: none;
              "
                        >
                            <Row>
                                <Col span="6">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        <span> 研发管理制度建设情况 </span>
                                    </div>
                                </Col>
                                <Col span="18">
                                    <div class="table_div" style="padding: 0 10px">
                                        <CheckboxGroup v-model="fourthForm.rdManage" @on-change="delAll('rdManage')">
                                            <Row>
                                                <Col span="6" style="text-align: left; padding: 0 20px">
                                                    <Checkbox label="A" style="font-size: 12px;    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;"><span class="hidden_span" style="width: 120px!important;" title="已制定企业研究开发的组织管理制度">已制定企业研究开发的组织管理制度</span>
                                                    </Checkbox>
                                                </Col>
                                                <Col span="6" style="text-align: left; padding: 0 20px">
                                                    <Checkbox label="B" style="font-size: 12px;    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;"><span class="hidden_span" style="width: 120px!important;"
                                title="已经建立了研发投入核算体系">已经建立了研发投入核算体系</span></Checkbox>
                                                </Col>
                                                <Col
                                                        span="6"
                                                        style="text-align: left; padding: 0 20px"
                                                >
                                                    <Checkbox label="C" style="font-size: 12px;    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;"><span title="已经编制了研发费用辅助账" class="hidden_span"
                                style="width: 120px!important;">已经编制了研发费用辅助账</span></Checkbox>
                                                </Col>
                                                <Col span="4" style="text-align: left; padding: 0 20px">
                                                    <Checkbox label="D" style="font-size: 12px;    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;"><span>以上都没有</span></Checkbox>
                                                </Col>
                                            </Row>
                                        </CheckboxGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="6">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        <span>自主研发及合作状况</span>
                                    </div>
                                </Col>
                                <Col span="18">
                                    <div class="table_div" style="padding: 0 10px;">
                                        <CheckboxGroup v-model="fourthForm.rdCooperation" @on-change="delAll('rdCooperation')">
                                            <Row>
                                                <Col span="9" style="text-align: left; padding: 0 20px">
                                                    <Checkbox label="A" style="font-size: 12px;    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;"><span title="已自主设立研发机构" class="hidden_span">已自主设立研发机构</span></Checkbox>
                                                </Col>
                                                <Col
                                                        span="11"
                                                        style="text-align: left; padding: 0 20px"
                                                >
                                                    <Checkbox label="B" style="font-size: 12px;    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;"><span title="与高校/科研院所开展产学研合作（具备签署技术开发协议并已支付委托经费" class="hidden_span">与高校/科研院所开展产学研合作（具备签署技术开发协议并已支付委托经费）</span>
                                                    </Checkbox>
                                                </Col>
                                                <Col span="4" style="text-align: left; padding: 0 20px">
                                                    <Checkbox label="C" style="font-size: 12px;    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;"><span>以上都没有</span></Checkbox>
                                                </Col>
                                            </Row>
                                        </CheckboxGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="6">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        <span>成果转化激励及创新情况</span>
                                    </div>
                                </Col>
                                <Col span="18">
                                    <div class="table_div" style="padding: 0 10px">
                                        <CheckboxGroup v-model="fourthForm.excitationInnovate" @on-change="delAll('excitationInnovate')">
                                            <Row>
                                                <Col span="9" style="text-align: left; padding: 0 20px">
                                                    <Checkbox label="A" style="font-size: 12px;    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;"><span title="已建立科技成果转化的组织实施与激励奖励制度" class="hidden_span">已建立科技成果转化的组织实施与激励奖励制度</span>
                                                    </Checkbox>
                                                </Col>
                                                <Col
                                                        span="11"
                                                        style="text-align: left; padding: 0 20px"
                                                >
                                                    <Checkbox label="B" style="font-size: 12px;    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;">
                                                        <span title="已具备开放式的创新创业平台" class="hidden_span">已具备开放式的创新创业平台</span>
                                                    </Checkbox>
                                                </Col>
                                                <Col span="4" style="text-align: left; padding: 0 20px">
                                                    <Checkbox label="C" style="font-size: 12px;    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;"><span>以上都没有</span></Checkbox>
                                                </Col>
                                            </Row>
                                        </CheckboxGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="6">
                                    <div
                                            class="table_div"
                                            style="background-color: rgba(205, 240, 243, 0.3)"
                                    >
                                        <span>人才培养及激励方法情况</span>
                                    </div>
                                </Col>
                                <Col span="18">
                                    <div class="table_div" style="padding:0 10px">
                                        <CheckboxGroup v-model="fourthForm.trainingIncentive" @on-change="delAll('trainingIncentive')">
                                            <Row>
                                                <Col span="9" style="text-align: left; padding: 0 20px">
                                                    <Checkbox label="A" style="font-size: 12px;    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;"><span title="已具备科技人员的培养进修、职工技能培训、优秀人才引进制度"
                                class="hidden_span">已具备科技人员的培养进修、职工技能培训、优秀人才引进制度</span></Checkbox>
                                                </Col>
                                                <Col
                                                        span="11"
                                                        style="text-align: left; padding: 0 20px"
                                                >
                                                    <Checkbox label="B" style="font-size: 12px;    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;">
                                                        <span title="已具备科技人员评价奖励制度" class="hidden_span">已具备科技人员评价奖励制度</span>
                                                    </Checkbox>
                                                </Col>
                                                <Col span="4" style="text-align: left; padding: 0 20px">
                                                    <Checkbox label="C" style="font-size: 12px;    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;">以上都没有
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                        </CheckboxGroup>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div
                                style="height: 180px; border-top: none; margin-top: 50px"
                                class="next_style common_liucheng;"
                        >
                            <div
                                    style="height: 40px; width: 120px"
                                    class="button_style"
                                    @click="current = 1"
                            >
                                上一步
                            </div>
                            <div
                                    style="height: 40px; width: 120px; margin-left: 20px"
                                    class="button_style"
                                    @click="confirmRdManageBt()"
                            >
                                提交
                            </div>
                            <!--              <div-->
                            <!--                  style="-->
                            <!--                  height: 40px;-->
                            <!--                  width: 120px;-->
                            <!--                  background-color: #fc7c40;-->
                            <!--                  margin-left: 20px;-->
                            <!--                "-->
                            <!--                  class="button_style"-->
                            <!--                  @click="confirmFan()"-->
                            <!--              >-->
                            <!--                暂存-->
                            <!--              </div>-->
                        </div>
                    </div>
                </div>

                <div v-show="nowIndex == 0" style="height: auto">
                    <div
                            style="
              display: flex;
              justify-content: center;
              height: 70px;
              line-height: 70px;
              background-color: rgb(54, 194, 207);
              border: 1px solid rgb(54, 194, 207);
              color: white;
              font-size: 20px;
            "
                    >
            <span>{{ reportList.enterpriseName }}</span
            >&nbsp;&nbsp;
                        <span style="margin-left: 20px"
                        >国家高新技术企业测评报告
              <span>({{ reportList.createTime }})</span></span
                        >
                    </div>
                    <div style="height:auto; border: 1px solid rgb(218, 226, 229)">
                        <div style="height: 370px; padding-top: 70px" class="div_center">
                            <Row :gutter="16">
                                <Col span="12">
                                    <div style="height: 220px">
                                        <div style="height: 44px; line-height: 44px">
                      <span
                              style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                          padding-right: 10px;
                        "
                      >企业基本情况得分</span
                      >
                                            <i-progress :percent="reportList.baseScore">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: rgb(54, 194, 207)"
                                                >{{ reportList.baseScore }}分</span
                                                >
                                            </i-progress>
                                        </div>
                                        <div style="height: 44px; line-height: 44px">
                      <span
                              style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                          padding-right: 10px;
                        "
                      >企业加分项得分</span
                      >
                                            <i-progress :percent="reportList.additionalScore">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: rgb(54, 194, 207)"
                                                >{{ reportList.additionalScore }}分</span
                                                >
                                            </i-progress>
                                        </div>
                                        <div style="height: 44px; line-height: 44px">
                      <span
                              style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                              padding-right: 10px;
                        "
                      >企业财务状况</span
                      >
                                            <i-progress :percent="reportList.financeScore">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: rgb(54, 194, 207)"
                                                >{{ reportList.financeScore }}分</span
                                                >
                                            </i-progress>
                                        </div>
                                        <div style="height: 44px; line-height: 44px">
                      <span
                              style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                              padding-right: 10px;
                        "
                      >企业知识产权状况</span
                      >
                                            <i-progress :percent="reportList.totalScore">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: rgb(54, 194, 207)"
                                                >{{ reportList.totalScore }}分</span
                                                >
                                            </i-progress>
                                        </div>
                                        <div style="height: 44px; line-height: 44px">
                      <span
                              style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                              padding-right: 10px;
                        "
                      >企业研发管理状况</span
                      >
                                            <i-progress :percent="reportList.manageScore">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: rgb(54, 194, 207)"
                                                >{{ reportList.manageScore }}分</span
                                                >
                                            </i-progress>
                                        </div>
                                    </div>
                                </Col>
                                <Col span="12">
                                    <div
                                            style="
                      font-size: 14px;
                      height: 220px;
                      border: 1px solid rgb(205, 240, 243);
                      border-right: none;
                      border-bottom: none;
                    "
                                    >
                                        <Row>
                                            <Col span="18">
                                                <div class="table-box">企业测评总得分</div>
                                            </Col>
                                            <Col span="6">
                                                <div class="table-box-double">
                                                    {{ reportList.totalScore }}分
                                                </div>
                                            </Col>
                                            <Col span="18">
                                                <div class="table-box" style="display: flex;justify-content: space-between;align-items: center">
                                                    <span>国家高新技术企业认证得分要求</span>
                                                    <!--                          <div-->
                                                    <!--                              v-if="data3.countryEval == 'N'"-->
                                                    <!--                              class="arrow_ld"-->
                                                    <!--                              style="background-color: orange"-->
                                                    <!--                          >-->
                                                    <!--                            <Icon type="md-close"/>-->
                                                    <!--                          </div>-->

                                                </div>
                                            </Col>
                                            <Col span="6">
                                                <div class="table-box-double" v-if="data3.countryLevel == '无'">
                                                    <div class="arrow_ld">
                                                        <Icon type="md-checkmark"/>
                                                    </div>
                                                </div>
                                                <div v-else class="table-box-double">
                                                    {{data3.countryLevel}}分
                                                </div>
                                            </Col>
                                            <Col span="18">
                                                <div class="table-box" style="display: flex;justify-content: space-between;align-items: center">
                                                    <span>{{ reportList.provinceName }}高新技术企业入库标准得分要求</span>
                                                </div>
                                            </Col>
                                            <Col span="6">
                                                <div class="table-box-double" v-if="data3.provinceLevel == '无'">
                                                    <div class="arrow_ld">
                                                        <Icon type="md-checkmark"/>
                                                    </div>
                                                </div>
                                                <div v-else class="table-box-double">
                                                    {{data3.provinceLevel}}分
                                                </div>
                                            </Col>
                                            <Col span="18">
                                                <div class="table-box" style="display: flex;justify-content: space-between;align-items: center">
                                                    <span>{{ reportList.provinceName }}{{ reportList.cityName }}高新技术企业入库标准得分要求</span>
                                                </div>
                                            </Col>
                                            <Col span="6">
                                                <div class="table-box-double" v-if="data3.cityLevel == '无'">
                                                    <div class="arrow_ld">
                                                        <Icon type="md-checkmark"/>
                                                    </div>
                                                </div>
                                                <div v-else class="table-box-double">
                                                    {{data3.cityLevel}}分
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div style="height: 400px; padding-top: 50px" class="div_center">
                            <div
                                    style="
                  font-size: 14px;
                  height: 300px;
                  border: 1px solid rgb(205, 240, 243);
                  border-right: none;
                  border-bottom: none;
                "
                            >
                                <Row>
                                    <Col span="6">
                                        <div
                                                class="table-box"
                                                style="
                        height: 50px;
                        text-align: center;
                        background-color: rgb(54, 194, 207);
                        color: white;

                      "
                                                title="国家高新技术企业认证基本条件达成情况"
                                        >
                                            国家高新技术企业认证基本条件达成情况
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            国家高新技术企业认证标准
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            {{ reportList.provinceName }}国高企入库标准
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            {{ reportList.cityName }}国高企入库标准
                                        </div>
                                    </Col>
                                </Row>
                                <Row v-for="(item,index) in this.data2" :key="index">
                                    <Col span="6">
                                        <div
                                                class="table-box"
                                                style="height: 50px; text-align: center"
                                                :title="item.itemCondition"
                                        >
                                            {{ item.itemCondition }}
                                        </div>
                                    </Col>
                                    <Col span="3">
                                        <div class="table-box-double" style="height: 50px" :title="item.countryLevel">
                                            {{ item.countryLevel }}
                                        </div>
                                    </Col>
                                    <Col span="3">
                                        <div class="table-box-double" style="height: 50px">
                                            <div
                                                    v-if="item.countryEval == 'N'"
                                                    class="arrow_ld"
                                                    style="background-color: orange"
                                            >
                                                <Icon type="md-close"/>
                                            </div>
                                            <div v-else class="arrow_ld">
                                                <Icon type="md-checkmark"/>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span="3">
                                        <div class="table-box-double" style="height: 50px" :title="item.provinceLevel">
                                            {{ item.provinceLevel }}
                                        </div>
                                    </Col>
                                    <Col span="3">
                                        <div class="table-box-double" style="height: 50px">
                                            <div
                                                    v-if="item.provinceEval == 'N'"
                                                    class="arrow_ld"
                                                    style="background-color: orange"
                                            >
                                                <Icon type="md-close"/>
                                            </div>
                                            <div v-else class="arrow_ld">
                                                <Icon type="md-checkmark"/>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span="3">
                                        <div class="table-box-double" style="height: 50px" :title="item.cityLevel"> {{ item.cityLevel }}</div>
                                    </Col>
                                    <Col span="3">
                                        <div class="table-box-double" style="height: 50px">
                                            <div
                                                    v-if="item.cityEval == 'N'"
                                                    class="arrow_ld"
                                                    style="background-color: orange"
                                            >
                                                <Icon type="md-close"/>
                                            </div>
                                            <div v-else class="arrow_ld">
                                                <Icon type="md-checkmark"/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        </div>
                        <div
                                style="height: auto; border-bottom: none; padding-top: 50px"
                                class="div_center"
                        >
                            <div
                                    style="
                  font-size: 14px;
                  height: auto;
                  border: 1px solid rgb(205, 240, 243);
                  border-right: none;
                  border-bottom: none;
                "
                            >
                                <Table
                                        border
                                        :columns="columns1"
                                        :data="data1"
                                        :disabled-hover="true"
                                        id="origin"
                                ></Table>
                            </div>
                        </div>
                        <div
                                style="height: 180px; border-top: none"
                                class="next_style common_liucheng;"
                        >
                            <div style="height: 40px; width: 120px;display: none" class="button_style">
                                下载报告
                            </div>
                            <div
                                    @click="reTest()"
                                    style="
                  height: 40px;
                  width: 120px;
                  margin-left: 20px;
                  background-color: rgb(54, 194, 207);
                "
                                    class="button_style"
                            >
                                重新测评
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";
    import {
        getTechnologyField,
        confirmBase,
        confirmIp,
        confirmFinance,
        confirmRdManage,
        getReport,
        getEnterpriseInfo,
        getProvince,
        getByParentCode,
        getEvalId,
        getBaseInfo,
        getSecondInfo,
        getThirdInfo,
        getFourthInfo
    } from "@/plugins/api/EnterpriseExam";
    import {
        isCorrectPassword,
        isPhoneNumber,
        successToast,
        isNumber,
        warnToast,
        errorToast,
    } from "@/plugins/tools/util";


    export default {
        name: "EnterprisePhysicalExamination",
        mounted() {
            this.ReportIfExist()

        },
        data() {
            return {
                oldEvalId: "",
                cityData: {},
                provinceData: {"": ''},
                thisYear: "",
                evalId: "",
                transfer: true,
                secondFlag: false,
                thirdFlag: false,
                // 第一步from
                baseForm: {
                    awards: "",
                    contactTel: "",
                    contactUser: "",
                    creditCode: "",
                    detailAddress: "",
                    mptCode: "",
                    punishment: "",
                    registerTime: "",
                    researcherNumber: "",
                    staffTotal: "",
                },

                // 第二步form
                ipList1: [
                    {
                        ipClass: 1,
                        ipItemCode: "FMZL",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "ZWXPZ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "NZWPZ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "GJXY",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "BHPZ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "SJZYQ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                ],
                orgParticipation:1,
                threeYearIpTotal:0,
                relatedNumber:0,
                ipList2: [
                    {
                        ipClass: 2,
                        ipItemCode: "SYXXZL",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 2,
                        ipItemCode: "RJZZQ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                ],
                data3: [],
                reportList: [], //报告内容
                //第三步form
                financeFrom: {
                    evalId: "",
                    last1stYearNetAssetsTotal: 0,
                    last1stYearRdInvestmentTotal: 0,
                    last1stYearSaleRevenue: 0,
                    last2ndYearNetAssetsTotal: 0,
                    last2ndYearRdInvestmentTotal: 0,
                    last2ndYearSaleRevenue: 0,
                    last3rdYearNetAssetsTotal: 0,
                    last3rdYearRdInvestmentTotal: 0,
                    last3rdYearSaleRevenue: 0,
                    lastYearHighTechRate: "A",
                    threeYearDomesticRdRate: "B",
                    threeYearRdRate: "A",
                },

                // 四form
                fourthForm: {
                    evalId: "22",
                    excitationInnovate: [],
                    rdCooperation: [],
                    rdManage: [],
                    trainingIncentive: [],
                },
                technologyList: [],
                nowIndex: 1,
                has: "有",
                nohas: false,
                hjqk: "",
                jsly: "",
                sciencePeople: [],
                current: 0,
                tab_height: 0,
                tab_list: ["国家高新技术企业体检"],
                isChoose: 0,
                contentIndex: 0,
                data2: [],
                keyword: "",
                columns1: [
                    {
                        title: "国家高新技术企业认证具体得分情况",
                        key: "itemCondition",
                        width: "600px",
                        className: "demo-table-info-column",
                    },
                    {
                        title: "国家高新技术企业认证标准",
                        key: "countryEval",
                    },
                    {
                        title: "江苏省国企入库标准",
                        key: "provinceEval",
                    },
                    {
                        title: "南京市国高企入库标准",
                        key: "cityEval",
                    },
                ],
                IP_ITEM_List: [],
                BASE_INF_List: [],
                data1: [],
            };
        },
        methods: {

            delAll(name) {
                if (name != 'rdManage') {
                    if (this.fourthForm[name].length >= 2 && this.fourthForm[name].indexOf("C") > -1) {
                        this.fourthForm[name] = ['C']
                    }
                } else if (name == 'rdManage') {
                    if (this.fourthForm[name].length >= 2 && this.fourthForm[name].indexOf("D") > -1) {
                        this.fourthForm[name] = ['D']
                    }

                }

            },
            // 是否有报告
            async ReportIfExist() {
                let json = await getEvalId();
                if (json.code == 200) {
                    if (json.data) {
                        this.oldEvalId = json.data
                        this.getReportFun();
                    } else {
                        this.changeHeight();
                        getEnterpriseInfo().then((res) => {
                            if (res.code == 200) {
                                this.baseForm.contactTel = res.data.contactTel;
                                this.baseForm.staffTotal = res.data.staffTotal;
                                this.baseForm.detailAddress = res.data.site;
                                this.baseForm.registerTime = res.data.registerTime;
                                this.baseForm.contactUser = res.data.legalRepresentative;
                                this.baseForm.creditCode = res.data.creditCode;
                                this.baseForm.enterpriseName = res.data.enterpriseName;
                                this.baseForm.provinceName = "";
                                this.baseForm.cityName = "";
                                this.baseForm.provinceCode = "";
                                this.baseForm.cityCode = "";
                                this.baseForm.punishment = 1
                            }
                        });
                        getProvince().then((res) => {
                            if (res.code == 200) {
                                this.provinceData = res.data
                            }
                        })
                        this.getTechnology();
                        let date = new Date();
                        this.thisYear = date.getFullYear();
                    }

                }
            },
            // 得到报告
            async getReportFun() {
                let data = {
                    evalId: this.evalId == "" ? this.oldEvalId : this.evalId,
                };
                let json = await getReport(data);
                if (json.code == "200") {
                    this.reportList = json.data;
                    this.columns1[2].title = this.reportList.provinceName + '国企入库标准';
                    this.columns1[3].title = this.columns1[3].title = this.reportList.cityName + '国企入库标准'
                    let dateee2 = new Date(this.reportList.createTime).toJSON();
                    this.reportList.createTime = new Date(
                        +new Date(dateee2) + 8 * 3600 * 1000
                    )
                        .toISOString()
                        .replace(/T/g, " ")
                        .replace(/\.[\d]{3}Z/, "")
                        .split(" ")[0];
                    this.data1 = this.reportList.itemList;
                    this.data2 = this.reportList.baseList;
                    this.data3 = this.reportList.headList;
                    if (!this.data3['provinceLevel']) {
                        this.data3['provinceLevel'] = '无'
                    }
                    if (!this.data3['countryLevel']) {
                        this.data3['countryLevel'] = '无'
                    }
                    if (!this.data3['cityLevel']) {
                        this.data3['cityLevel'] = '无'
                    }

                    for (let i = 0; i < this.data2.length; i++) {
                        if (!this.data2[i]['provinceLevel']) {
                            this.data2[i]['provinceLevel'] = '无'
                        }
                        if (!this.data2[i]['countryLevel']) {
                            this.data2[i]['countryLevel'] = '无'
                        }
                        if (!this.data2[i]['cityLevel']) {
                            this.data2[i]['cityLevel'] = '无'
                        }
                    }
                    this.data1.forEach((item, index) => {
                        if (!item['countryEval']) {
                            item['countryEval'] = '0分'
                        } else {
                            item['countryEval'] += '分'
                        }
                        if (!item['provinceEval']) {
                            item['provinceEval'] = '0分'
                        } else {
                            item['provinceEval'] += '分'
                        }
                        if (!item['cityEval']) {
                            item['cityEval'] = '0分'
                        } else {
                            item['cityEval'] += '分'
                        }

                    })
                    this.nowIndex = 0;
                    this.changeHeight();
                    this.$nextTick(() => {
                        for (let i = 0; i < this.ipList2.length; i++) {
                            this.ipList2[i].selfDevNumber = 0;
                            this.ipList2[i].traGiftMergerNumber = 0
                        }
                        for (let i = 0; i < this.ipList1.length; i++) {
                            this.ipList1[i].selfDevNumber = 0;
                            this.ipList1[i].traGiftMergerNumber = 0
                        }


                        for (let item in this.fourthForm) {
                            if (item == 'evalId') {
                                this.fourthForm[item] = "";
                            } else {
                                this.fourthForm[item] = [];
                            }
                        }
                    })

                } else {
                    this.nowIndex = 1;
                    this.changeHeight();
                    this.getTechnology();
                    let date = new Date();
                    this.thisYear = date.getFullYear();
                    let rjson = await getBaseInfo({evalId: this.oldEvalId});
                    if (rjson.code == 200) {
                        for (let item in this.baseForm) {
                            this.baseForm[item] = rjson.data[item] + ""
                        }
                        getProvince().then((res) => {
                            if (res.code == 200) {
                                this.provinceData = res.data
                                this.baseForm.provinceName = this.provinceData[rjson.data['provinceCode']]
                            }
                        })
                        this.baseForm.provinceCode = rjson.data['provinceCode'] + ""
                        this.baseForm.cityCode = rjson.data['cityCode'] + ''

                        this.getProvinceT(this.baseForm.provinceCode)
                        this.nowIndex = 1;
                        this.changeHeight();
                        document.body.scrollTop = 0
                        document.documentElement.scrollTop = 0
                    }
                }
            },
            async firstNext() {
                this.baseForm.id = this.oldEvalId;
                for (let item in this.baseForm) {
                    if (item !== "detailAddress" || item !== "awards") {
                        if (this.baseForm[item] === "" && item != 'id') {
                            warnToast("请将数据填写完成");
                            return;
                        }
                    }
                }
                if(isNumber(this.baseForm.staffTotal) == false){
                    warnToast("职工人数请输入数字")
                    return
                };
                if(isNumber(this.baseForm.researcherNumber) == false){
                    warnToast("科技员工人数请输入数字")
                    return
                };
                let json = await confirmBase(this.baseForm);
                if (json.code == "200") {
                    this.evalId = json.data.evalId;
                    this.current = 1;
                    if (this.oldEvalId != "") {
                        getSecondInfo({
                            evalId: this.oldEvalId
                        }).then(res => {
                            if (res.code == 200) {
                                if (JSON.stringify(res.data) != '{}') {
                                    this.ipList1 = res.data.ipList.slice(0, 6)
                                    this.ipList2 = res.data.ipList.slice(6, res.data.ipList.length);
                                    this.relatedNumber = res.data.relatedNumber
                                    this.threeYearIpTotal = res.data.threeYearIpTotal
                                    this.orgParticipation = res.data.orgParticipation + ""
                                }

                            }
                        })
                    }
                    this.changeHeight();
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                } else {
                    errorToast(json.message)
                }


            },

            async secondNext() {
                let data = {
                    id:this.oldEvalId,
                    evalId: this.evalId,
                    relatedNumber:this.relatedNumber,
                    threeYearIpTotal:this.threeYearIpTotal,
                    orgParticipation:this.orgParticipation,
                    ipList: this.ipList1.concat(this.ipList2),
                };
                if(isNumber(this.relatedNumber) == false){
                    warnToast("近三年知识产权总量（含已授权和申请中）该项请输入数字")
                    return ""
                }
                if(isNumber(this.threeYearIpTotal) == false){
                    warnToast("与主要产品或服务相关的知识产权数量 该项请输入数字")
                    return ""
                }

                for(let i =0;i<data.ipList.length;i++){
                    if(isNumber(data.ipList[i].selfDevNumber) ==false || isNumber(isNumber(data.ipList[i].traGiftMergerNumber) ==false)){
                        warnToast("请输入正整数")
                        return  ''
                    }
                }
                let json = await confirmIp(data);

                if (json.code == "200") {
                    this.current = 2;
                    if (this.oldEvalId != "") {
                        getThirdInfo({evalId: this.oldEvalId}).then(res => {
                            if (res.code == 200) {
                                if (JSON.stringify(res.data) != '{}') {
                                    this.financeFrom = res.data
                                }

                            }
                        });
                    }

                    this.changeHeight();
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                }else{
                    warnToast(json.message)
                }


            },
            async confirmRdManageBt() {
                this.financeFrom.evalId = this.evalId;
                let json = await confirmFinance(this.financeFrom);
                if (json.code == "200") {
                    this.fourthForm.evalId = this.evalId;
                    let submitData = JSON.parse(JSON.stringify(this.fourthForm));
                    for (let item in submitData) {
                        if (!submitData[item]) {
                            warnToast("请将数据填写完整");
                            return;
                        }
                        if (typeof submitData[item] == 'object') {
                            submitData[item] = submitData[item].join(',')
                        }
                    }

                    submitData.evalId = this.evalId;
                    submitData["evalStatus"] = 1;
                    let json = await confirmRdManage(submitData);
                    if (json.code == "200") {
                        this.getReportFun();
                        this.nowIndex = 0;
                        document.body.scrollTop = 0
                        document.documentElement.scrollTop = 0;
                        this.changeHeight();
                    } else {
                        errorToast(json.message)
                    }
                } else {
                    errorToast(json.message)
                }


            },
            reTest() {
                this.oldEvalId = "";
                this.nowIndex = 1;
                this.current = 0;
                this.changeHeight();
                this.$nextTick(() => {
                    for (let i = 0; i < this.ipList2.length; i++) {
                        this.ipList2[i].selfDevNumber = 0;
                        this.ipList2[i].traGiftMergerNumber = 0
                    }
                    for (let i = 0; i < this.ipList1.length; i++) {
                        this.ipList1[i].selfDevNumber = 0;
                        this.ipList1[i].traGiftMergerNumber = 0
                    }
                    this.threeYearIpTotal = 0;
                    this.orgParticipation = 1;
                    this.relatedNumber = 0;
                    for (let item in this.fourthForm) {
                        if (item == 'evalId') {
                            this.fourthForm[item] = "";
                        } else {
                            this.fourthForm[item] = [];
                        }
                    }
                })

                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
                getEnterpriseInfo().then((res) => {
                    if (res.code == 200) {
                        this.baseForm.contactTel = res.data.contactTel;
                        this.baseForm.staffTotal = res.data.staffTotal;
                        this.baseForm.detailAddress = res.data.site;
                        this.baseForm.registerTime = res.data.registerTime;
                        this.baseForm.contactUser = res.data.legalRepresentative;
                        this.baseForm.creditCode = res.data.creditCode;
                        this.baseForm.enterpriseName = res.data.enterpriseName;
                        this.baseForm.provinceName = "";
                        this.baseForm.cityName = "";
                        this.baseForm.provinceCode = "";
                        this.baseForm.cityCode = "";
                        this.baseForm.punishment = 1;
                        this.researcherNumber = "";
                    }
                });
                getProvince().then((res) => {
                    if (res.code == 200) {
                        this.provinceData = res.data
                    }
                })
                this.getTechnology();
                let date = new Date();
                this.thisYear = date.getFullYear();
            },
            // async confirmFan() {
            //   this.financeFrom.evalId = this.evalId;
            //   let json = await confirmFinance(this.financeFrom);
            //   if (json.code == "200") {
            //     successToast("暂存成功");
            //     this.thirdFlag = true;
            //   } else {
            //     errorToast(json.message)
            //   }
            // },

            // async confirmIpBu() {
            //
            //
            // },

            // async confirmBaseBu() {
            //   for (let item in this.baseForm) {
            //     if (item !== "detailAddress" || item !== "awards") {
            //       if (this.baseForm[item] === "") {
            //         warnToast("请将数据填写完成");
            //         return;
            //       }
            //     }
            //   }
            //
            //   let json = await confirmBase(this.baseForm);
            //   if (json.code == "200") {
            //     this.evalId = json.data.evalId;
            //     successToast("暂存成功");
            //
            //   } else {
            //     errorToast(json.message)
            //   }
            // },

            async getTechnology() {
                let json = await getTechnologyField();
                if (json.code == "200") {
                    for (let i in json.data) {
                        let obj = {};
                        obj["name"] = json.data[i];
                        obj["value"] = i;
                        this.technologyList.push(obj);
                    }
                }
            },
            pre() {
                this.current -= 1;
            },
            getProvinceT(item) {
                this.baseForm.provinceName = this.provinceData[item]
                getByParentCode({parentCode: item}).then((res) => {
                    if (res.code == 200) {
                        this.cityData = res.data;
                        if (this.baseForm.cityCode != "") {
                            this.getCity(this.baseForm.cityCode)
                        }
                    }
                })
            },

            getCity(item) {
                this.baseForm.cityName = this.cityData[item]
            },
            showTab(index) {
                this.isChoose = index;
                this.changeHeight();
            },
            changeHeight() {
                let that = this;
                this.$nextTick(() => {
                    that.tab_height =
                        Number($(".right_content_parent").height() + 190) -
                        (Number(document.body.clientHeight) - 101) >
                        20
                            ? $(".right_content_parent").height() + 200 + "px"
                            : Number(document.body.clientHeight) - 101 + "px";
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    /deep/ .ivu-input:focus {
        border: 1px solid rgba(205, 240, 243, 1) !important;
        box-shadow: none !important;
    }

    .ivu-input:focus {
        outline: none !important;
    }

    .yi_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }

    /deep/ .ivu-input-wrapper {
        vertical-align: baseline !important;
    }

    /deep/ .table_div .ivu-input {
        height: 50px !important;
        text-align: center !important;
        padding: 0 0;
        border-color: rgba(205, 240, 243, 1);
        border-bottom: none !important;
    }

    /deep/ .table_div .ivu-date-picker-rel .ivu-input {
        height: 40px !important;
        text-align: center !important;
        padding: 0 0;
        border: 1px solid #dcdee2 !important;

    }

    .first:last-child .ivu-input {
        border-bottom: 1px solid rgba(205, 240, 243, 1);
    }

    /deep/ .table_div2 .ivu-input {
        height: 50px !important;
        text-align: center !important;
        padding: 0 0;
        border-color: rgba(205, 240, 243, 1);
    }

    /deep/ .ivu-select {
        width: 50% !important;
    }

    /deep/ .ivu-input-wrapper {
        vertical-align: center !important;
    }

    /deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-tail > i:after {
        background-color: rgb(54, 194, 207) !important;
    }

    /deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-head-inner {
        border-color: rgb(54, 194, 207) !important;
    }

    /deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-head-inner span,
    .ivu-steps-item.ivu-steps-status-finish
    .ivu-steps-head-inner
    > .ivu-steps-icon {
        color: rgb(54, 194, 207) !important;
    }

    .table_div {
        height: 50px;
        text-align: center;
        line-height: 50px;
        width: 100%;
        border-right: 1px solid rgba(205, 240, 243, 1);
        border-bottom: 1px solid rgba(205, 240, 243, 1);
    }

    /deep/ .ivu-select-selection {
        border-radius: 0;
    }

    .table_div2 {
        height: 50px;
        text-align: center;
        line-height: 50px;
        width: 100%;
    }

    .table_div_two {
        border-right: none !important;
    }

    /deep/ .ivu-steps-item.ivu-steps-status-process .ivu-steps-head-inner {
        background: rgb(54, 194, 207);
        border: rgb(54, 194, 207);
    }

    /deep/ .ivu-steps-head-inner span {
    }

    /deep/ .ivu-steps .ivu-steps-title {
        display: block;
        margin-top: 25px;
    }

    /deep/ .ivu-table-cell {
        text-align: center !important;

        & > span {
            text-align: center !important;
        }
    }

    /deep/ .ivu-table th {
        height: 50px !important;
        text-align: center;
    }

    .ivu-table-row {
        height: 50px !important;
    }

    /deep/ .ivu-table td {
        height: 50px !important;
    }

    /deep/ .ivu-radio-inner:after {
        background-color: rgb(54, 194, 207);
    }

    .arrow_ld {
        color: white !important;
        display: inline-block;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background: rgb(54, 194, 207);
        line-height: 14px;
        text-align: center;
    }

    .div_first_td {
        background-color: rgb(54, 194, 207) !important;
        color: white;
    }

    .table-box {
        width: 100%;
        height: 55px;
        background-color: #f3fcfc;
        line-height: 55px;
        text-align: left;
        border-bottom: 1px solid rgba(205, 240, 243, 1);
        border-right: 1px solid rgb(205, 240, 243);
        padding: 0 10px;
        overflow: hidden;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .table-box2 {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: left;
        border-bottom: 1px solid rgba(205, 240, 243, 1);
        border-right: 1px solid rgb(205, 240, 243);
        font-size: 14px;
    }

    .table-box-double {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: center;
        border-bottom: 1px solid rgb(205, 240, 243);
        border-right: 1px solid rgb(205, 240, 243);
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 10px;
    }

    .ivu-progress {
        display: inline-block !important;
        width: 65% !important;
    }

    .div_center {
        width: 95%;
        margin-left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted rgb(218, 226, 229);
    }

    .neirong {
        margin-left: 130px;

        .download_span {
            color: blue;
            display: inline-block;
            margin-right: 30px;
        }

        p {
            display: inline-block;
            margin-bottom: 20px;
            font-size: 14px;
            color: black;
        }
    }

    .bottom_style {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: -110px;
    }

    .common_liucheng:nth-child(1) {
        border: none;
    }

    .common_liucheng {
        border-top: 2px dotted #f5f5f5;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        & > span {
            display: block;
            padding-left: 5px;
            padding-top: 38px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            color: rgb(51, 51, 51);
            margin-bottom: 60px;
        }
    }

    .next_style {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right_real_content {
        margin-bottom: 50px;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 40px;
        overflow: hidden;
    }

    .right_header {
        width: 95%;
        padding-top: 20px;
        height: 140px;
        position: relative;
        margin-bottom: 40px !important;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted #f5f5f5;
        overflow: hidden;

        span {
            display: block;
            margin-bottom: 20px;
        }
    }

    .button_style {
        display: inline-block;
        border-radius: 2px;
        background-color: rgb(54, 194, 207);
        width: 60px;
        height: 40px;
        z-index: 51;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        color: white;
        cursor: pointer;
    }

    .input_search,
    .input_search:focus {
        border-width: 1px;
        border-color: rgb(55, 197, 207);
        border-style: solid;
        border-radius: 2px;
        background-color: rgb(255, 255, 255);
        box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
        width: 300px;
        height: 40px;
        outline: none;
    }

    .isChoose {
        color: #37c5cf;
    }

    .right_content {
        width: 90%;
        /*border-width: 1px;*/
        /*border-color: rgb(218, 226, 229);*/
        /*border-style: solid;*/
        background-color: rgb(255, 255, 255);
        min-width: 900px;
        height: auto;
    }

    .right_content_parent {
        box-sizing: border-box;
        width: 80%;
        min-width: 960px;
        float: left;
        display: flex;
        justify-content: center;
        padding-top: 50px;
        position: relative;
        margin-bottom: 130px;
    }

    .left_tab_parent {
        float: left;
        min-width: 240px;
        width: 20%;
        background-color: rgb(250, 250, 252);

        .left_tab {
            margin-top: 60px;

            div {
                width: 210px;
                margin: 0 auto;
                height: 48px;
                line-height: 48px;
                font-size: 14px;
                box-sizing: border-box;
                padding-left: 31px;
                margin-bottom: 20px;
                border-width: 1px;
                border-color: rgb(235, 235, 235);
                border-style: solid;
                background-color: rgb(255, 255, 255);
                cursor: pointer;
            }
        }
    }

    /deep/ .demo-table-info-column {
        background-color: #f3fcfc;
        color: white;
    }

    /deep/ .ivu-table-tbody .demo-table-info-column {
        color: black !important;
    }

    .hidden_span {
        width: 180px !important;
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 2;
        //-webkit-box-orient: vertical;
        //padding: 0 10px!important;
        //width: 200px!important;
        //overflow: hidden!important;
        //text-overflow: ellipsis!important;
        //white-space: nowrap!important;
        //display: inline-block;
    }

</style>
